<template>
	<div class="app-box">
		<!-- <div class="title-box">
			<div class="line"></div>
			<p class="title">线下预约</p>
		</div> -->
		<a-tabs :activeKey="query.deal_type" @change="callbackChange">
			<a-tab-pane :key="1" tab="待服务">
			</a-tab-pane>
			<a-tab-pane :key="2" tab="待核销" force-render>
			</a-tab-pane>
			<a-tab-pane :key="3" tab="已核销">
			</a-tab-pane>
			<a-tab-pane :key="4" tab="已取消">
			</a-tab-pane>
		</a-tabs>
		<a-divider style="margin-left: -10px" class="custom-divider" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="会员卡">
					<a-input v-model="query.member_card" placeholder="请输入会员卡号"></a-input>
				</a-form-model-item>
				<a-form-model-item label="会员电话">
					<a-input v-model="query.phone" placeholder="请输入手机号"></a-input>
				</a-form-model-item>
				<a-form-model-item label="服务名称">
					<a-input v-model="query.service_name" placeholder="请输入"></a-input>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<!-- <a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style="margin-left: 10px">+新增记录</a-button>
		</a-row> -->
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="level_id" slot-scope="text, record">
				{{ getName(levelList, record.level_id, "level_id", "name") }}
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="info(record)">详情</a>
					<div v-if="query.deal_type == 1">
						<a @click="toEdit(record)">受理</a>
					</div>
					<div v-if="query.deal_type == 2">
						<a @click="toEdit2(record)">受理</a>
					</div>
					<div v-if="query.deal_type == 4 || query.deal_type == 3">
						<a @click="toEdit3(record)">记录</a>
					</div>
				</a-space>
			</span>
		</a-table>

		<!-- 抽屉1 添加/修改 -->
		<a-drawer :title="deal_type == 3 ? '记录' : '受理'" :width="720" :visible="visible1"
			:body-style="{ paddingBottom: '80px' }" @close="onClose1">
			<a-form-model :model="customerForm" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="deal_type != 3">
				<a-form-model-item label="受理方式" ref="type" prop=" type">
					<a-select placeholder="请选择受理方式" v-model="customerForm.type">
						<a-select-option :value="0" :key="0">
							调整时间
						</a-select-option>
						<a-select-option :value="1" :key="1">
							确认
						</a-select-option>
						<a-select-option :value="2" :key="2">
							取消
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="adjust_time" label="调整时间" prop="adjust_time" v-if="customerForm.type == 0">
					<a-input v-model="customerForm.adjust_time" placeholder="请输入调整时间" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" />
				</a-form-model-item>
				<a-form-model-item ref="remark" label="受理备注" prop="remark">
					<a-input type="textarea" v-model="customerForm.remark" placeholder="请输入备注" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" />
				</a-form-model-item>
			</a-form-model>
			<a-divider v-if="deal_type != 3" />
			<a-descriptions title="处理记录" :column="1">
				<template :value="item.deal_id" v-for="item in dealList">
					<a-descriptions-item label="时间" :key="item.deal_id">
						{{ item.create_at_str }}
					</a-descriptions-item>
					<a-descriptions-item label="服务人员" :key="item.deal_id">
						{{ item.name }}
					</a-descriptions-item>
					<a-descriptions-item label="处理结果" :key="item.deal_id">
						{{ item.deal_type == 0 ? "调整时间" : item.deal_type == 1 ? "确认" : item.deal_type == 3 ? "调整时间" :
							item.deal_type == 4 ? "核销" : "取消" }}
					</a-descriptions-item>
					<a-descriptions-item label="调整时间" v-if="item.deal_type == 0 || item.deal_type == 4" :key="item.deal_id">
						{{ item.deal_time_str }}
					</a-descriptions-item>
					<a-descriptions-item label="备注" :key="item.deal_id">
						{{ item.remark }}
					</a-descriptions-item>
					<a-divider :key="item.deal_id" />
				</template>
			</a-descriptions>
			<!-- //列表 -->
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose1">
					取消
				</a-button>
				<a-button type="primary" @click="submitForm">
					确定
				</a-button>
			</div>
		</a-drawer>

		<!-- 抽屉2 查看详情 -->
		<a-drawer title="预约详情" :width="550" :visible="visible2" :body-style="{ paddingBottom: '80px' }" @close="onClose2">
			<a-descriptions :column="{ xs: 100000, sm: 10000, md: 1 }" bordered>
				<a-descriptions-item label=" 服务类型" :span="1">
					{{ item.type == 1 ? "会员套餐" : item == 2 ? "付费服务" : "线下提交" }}
				</a-descriptions-item>
				<a-descriptions-item label="订单时间">
					{{ item.create_at_str }}
				</a-descriptions-item>
				<a-descriptions-item label="服务名称">
					{{ item.service_name }}
				</a-descriptions-item>
				<a-descriptions-item label="用户姓名">
					{{ item.user_name }}
				</a-descriptions-item>
				<a-descriptions-item label="用户电话">
					{{ item.user_phone }}
				</a-descriptions-item>
				<a-descriptions-item label="联系人">
					{{ item.contact }}
				</a-descriptions-item>
				<a-descriptions-item label="联系电话">
					{{ item.contact_phone }}
				</a-descriptions-item>
				<a-descriptions-item label="基本情况描述">
					{{ item.content }}
				</a-descriptions-item>
			</a-descriptions>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose2">
					取消
				</a-button>
				<a-button type="primary" @click="onClose2">
					确定
				</a-button>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		let validateClass = (rule, value, callback) => {
			if (value) {
				callback(); // 通过验证
			} else {
				callback(new Error('请选择分类')); // 验证失败，返回错误信息
			}
		};
		let validateMoney = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入金额'));
			} else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
				callback(new Error('请输入正确的金额'));
			} else {
				callback();
			}
		};
		return {
			query: {
				page: 1,
				limit: 10,
				type: "online",
				deal_type: 1
			},
			deal_type: null,
			dealList: [],
			customer_id: null,
			pagetotal: 0,
			inputValue: '',
			visible1: false,
			visible2: false,
			item: {},
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			customerForm: {

			},

			columns: [
				{
					title: '会员ID',
					dataIndex: 'member_card',
				},
				{
					title: '会员姓名',
					dataIndex: 'name',
				},
				{
					title: '会员电话',
					dataIndex: 'phone',
				},
				{
					title: '会员等级',
					scopedSlots: {
						customRender: 'level_id',
					},
				},
				{
					title: '用户标签',
					dataIndex: 'tag',
				},
				{
					title: '联系人电话',
					dataIndex: 'contact_phone',
				},
				{
					title: '服务名称',
					dataIndex: 'service_name',
				},
				{
					title: '期望时间',
					dataIndex: 'res_time_str',
				},
				{
					title: '提交时间',
					dataIndex: 'create_at_str',
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},
			],
			classColumns: [
				{
					title: '名称',
					dataIndex: 'name',
					customRender: (text, record, index) => {
						return {
							children: text,
							attrs: {
								style: 'width: 60%;', // 指定列宽度为 30%
							},
						};
					},
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},],
			data: [],
			levelList: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
		this.getLevelList()
	},
	methods: {
		toEdit(record) {
			this.reservation_id = record.reservation_id
			this.getDealList(record.reservation_id)
			this.visible1 = true
			this.deal_type = 1
		},
		toEdit2(record) {
			this.reservation_id = record.reservation_id
			this.getDealList(record.reservation_id)
			this.visible1 = true
			this.deal_type = 2
		},

		toEdit3(record) {
			this.reservation_id = record.reservation_id
			this.getDealList()
			this.visible1 = true
			this.deal_type = 3
		},
		callbackChange(a) {
			this.query.deal_type = a
			this.getList()
		},
		blur() {
			console.log("失去焦点")
			this.getCustomerList('')
		},
		info(record) {
			this.$post(domain + '/admin/v1/reservation/info', { reservation_id: record.reservation_id }).then(res => {
				if (res.code === 0) {
					this.item = res.data
					this.visible2 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		submitForm() {
			let label = ""
			if (this.deal_type == 1) {
				label = "deal"
			} else if (this.deal_type == 2) {
				label = "hexiao"
			} else {
				this.visible1 = false
				this.initForm()
			}
			this.customerForm.reservation_id = this.reservation_id
			this.$post(domain + '/admin/v1/reservation/' + label, this.customerForm).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.visible1 = false
					this.initForm()
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		getDealList() {
			this.$post(domain + '/admin/v1/reservation/dealListNoPage', {
				reservation_id: this.reservation_id
			}).then(res => {
				if (res.code === 0) {
					this.dealList = res.data
					console.log("lieliebioabioa", this.dealList)
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit,
				type: "online",
				deal_type: 1
			}
			this.getList()
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		onClose1() {
			this.visible1 = false;
			this.initForm()
		},
		onClose2() {
			this.visible2 = false;
		},
		initForm() {
			this.reservation_id = null
			this.customerForm = {
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/reservation/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getLevelList() {
			this.$post(domain + '/admin/v1/customerLevel/listNoPage').then(res => {
				if (res.code === 0) {
					this.levelList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.custom-divider {
	border-top: none;
	border-bottom: none;
	height: 10px;
	/* 调整高度以达到空白条的效果 */
	margin: 20px 0;
	/* 调整间距 */
	background-color: #f0f0f0;
	/* 设置背景颜色 */
}

.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
